import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import { PrismicRichText } from "@prismicio/react"

const PrivacyPage = ({ data }) => {
  const prismicHomepage = data.allPrismicPrivacy.edges.slice(0, 1).pop().node
    .data
  return (
    <Layout showUsercentrics={false}>
      <section className="pt-16 md:pt-16">
        <div className="container mx-auto px-8">
          <PrismicRichText field={prismicHomepage.body.richText} />
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrivacyQuery {
    allPrismicPrivacy {
      edges {
        node {
          data {
            body {
              richText
            }
          }
        }
      }
    }
  }
`

export default PrivacyPage
